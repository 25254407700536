import React, { useContext } from 'react'
import Navigator from '../components/Navigator'
import { graphql } from 'gatsby'
import Seo from '../components/seo'
import * as css from './ReportDetailTemplate.module.scss'
import StrPage from '../components/StrPage'
import Header from '../components/header/Header'
import QandA from '../components/detail/QandA'
import ReportPage from '../components/detail/ReportPage'
import PinModal from '../components/detail/PinModal'
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../context/GlobalContextProvider'
import MetaControl from '../components/feature/meta-control/MetaControl'
import Profile from '../components/detail/Profile'

const ReportDetailTemplate = (props) => {
  const pageData = props.data.markdownRemark.frontmatter
  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)

  return (
    <main>
      <Seo title={pageData.title} lang={`en`}/>
      <PinModal/>
      <Header/>
      <div className={css.lSingleBody}>
        <article className={css.lSingleBody__main}>
          <div className={css.lSingleBody__main__zoomControl}>
            <MetaControl lang={'en'}/>
          </div>
          <div className={css.docMeta}>
            <h2 className={css.docMeta__title}>{pageData.title}</h2>
            <p className={css.docMeta__sub}>
              Text by {pageData.author}･
              <span className={css.docMeta__pageNum}>
                <StrPage num={pageData.pages.length}/>
              </span>
              <br/>
              Handwriting by {pageData.interpreter.name} (
              {pageData.interpreter.area})
            </p>

            {!pageData.interpreter.isProjectStaff && (
              <div className={css.notes}>
                <p>
                  Handwritten by {pageData.interpreter.name} through Freelancer, this report features black text for the main content and <span
                  className={css.colorRed}>personal comments in red.</span>
                </p>
              </div>
            )}
          </div>
          <div className={css.lPages}>
            {pageData.pages.map((page, i) => {
              return (
                <div className={css.lPages__page} key={i}>
                  <ReportPage
                    image={page.image}
                    pins={page.pins}
                    htmlContent={page.htmlContent}
                  />
                </div>
              )
            })}
          </div>

          <div className={css.lQandA}>
            <div className={css.profile}>
              <Profile profile={pageData.authorProfile} lang={`en`}/>
            </div>

            <QandA
              questions={pageData.interpreter.questions}
              name={pageData.interpreter.nameFull ? pageData.interpreter.nameFull : pageData.interpreter.name}
              area={pageData.interpreter.area}
              flag={pageData.interpreter.flag}
              lang={`en`}
            />

          </div>
        </article>
        <div
          className={
            css.lSingleBody__nav + ` ` + (!state.showNavigator && css.invisible)
          }
        >
          <Navigator lang={'en'}/>
        </div>

        {!state.showNavigator && (
          <button
            aria-label="Show Navigator"
            className={css.showNavigatorButton}
            onClick={() => {
              dispatch({
                type: `SHOW_NAVIGATOR`,
              })
            }}
            title={`Show Navigator`}
          />
        )}
      </div>
    </main>
  )
}

export default ReportDetailTemplate

export const pageQuery = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      frontmatter {
        title
        author
        authorProfile {
          name
          body
        }
        interpreter {
          area
          name
          nameFull
          isProjectStaff
          flag
          questions {
            answer
            question
          }
        }
        pages {
          image {
            childImageSharp {
              fluid(maxWidth: 1600, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          pins {
            image {
              childImageSharp {
                fluid(maxWidth: 1600, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
                fixed(width: 1600, height: 1600, quality: 90) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
            hlink
            label
            video
            x
            y
          }
          htmlContent
        }
      }
    }
  }
`
